<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

            <message-left-panel></message-left-panel>

            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='grid'>

                    <div class='col-12'>

                        <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <div class='header-left-section flex align-items-center justify-content-start mb-3 md:mb-0'>
                        <div class='align-items-center justify-content-start mb-0 md:mb-0'>
                            <h3 class='mb-0'>Giden Talepler</h3>
                        </div>
                    </div>
                </div>

                <span>
					<hr class="mb-4">
				</span>

                        <DataTable :value='dataList' :paginator='true' class='p-datatable-gridlines' :rows='10'
                                   dataKey='id' :rowHover='true' v-model:filters='filters' filterDisplay='menu'
                                   :loading='loadingIndicator' :filters='filters' responsiveLayout='scroll'
                                   :globalFilterFields="[ 'receiver.fullName','subject','status','type' ]">

                            <template #header>
                                <div class='flex justify-content-between flex-column sm:flex-row'>
                                    <span class='p-input-icon-left mb-2'>
                                        <i class='pi pi-search' />
                                            <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                                    </span>
                                </div>
                            </template>

                            <template #empty>
                                Mesaj bulunamadı.
                            </template>

                            <template #loading>
                                Mesaj listesi yükleniyor. Lütfen bekleyin.
                            </template>

                            <Column header='Tarih' filterField='insertDate' :showFilterMatchModes='false' style='width:9rem'>
                                <template #body='{data}'>
                                    {{ formatDate(data.insertDate) }}
                                </template>
                            </Column>

                            <Column field='receiver.fullName' header='Kime' filterField='receiver.fullName' :showFilterMatchModes='false' style='width:15rem'>
                            </Column>

                            <Column field='subject' header='Talep Başlığı' filterField='subject' :showFilterMatchModes='false'>
                            </Column>

                            <Column field='type' header='Durumu' dataType='boolean' bodyClass='text-center' style='width:4rem'>
                                <template #body='{data}'>
                                    <button class='p-button p-component p-button-rounded pi' :class="{'p-button-success pi-check': data.type == 'Normal', 'p-button-danger pi-bell': data.type != 'Normal'}"></button>
                                </template>
                            </Column>


                            <Column field='isSenderRead' header='Okundu' dataType='boolean' bodyClass='text-center' style='width:4rem'>
                                <template #body='{data}'>
                                    <i class='pi' style='font-size: 1.2rem;' :class="{'text-gray-500 pi-eye': data.isSenderRead, 'text-gray-500 pi-eye-slash': !data.isSenderRead}"></i>
                                </template>
                            </Column>
                            
<!--                            <Column field='isReceiverRead' header='Alıcı Okundu' dataType='boolean' bodyClass='text-center' style='width:4rem'>-->
<!--                                <template #body='{data}'>-->
<!--                                    <i class='pi' style='font-size: 2rem;' :class="{'text-green-500 pi-eye': data.isReceiverRead, 'text-pink-500 pi-eye-slash': !data.isReceiverRead}"></i>-->
<!--                                </template>-->
<!--                            </Column>-->

                            <Column field='status' header='Talep Durumu' dataType='boolean' bodyClass='text-center' style='width:4rem'>
                                <template #body='{data}'>
                                    <i class='pi' style='font-size: 1.2rem;' :class="{'text-pink-500 pi-unlock': data.status == 'Açık', 'text-green-500 pi-lock': data.status != 'Açık'}"></i>
                                </template>
                            </Column>

                            <Column header='Detay' bodyClass='text-center' style='width:4rem' :exportable='false'>
                                <template #body='slotProps'>
                                    <Button icon='pi pi-search' class='p-button-rounded p-button-primary'  @click='edit(slotProps.data)' />
                                </template>
                            </Column>



                        </DataTable>

                    </div>

                </div>

            </div>

        </div>


    </div>
</template>

<script>

import AdminToast from '@/components/shared/toast/adminToast';
import { getUserId } from '../common/commonConstantFunctions';
import MessageService from '@/services/messageService';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment/moment';
import MessageLeftPanel from '@/pages/messages/messageLeftPanel.vue';

export default {
    _messageService: null,
    components: { MessageLeftPanel, AdminToast },
    created() {
        this._messageService = new MessageService();
        this.initFilters();
        this.loadingIndicator = true;
    },
    async mounted() {
        const response = await this._messageService.getMessagesBySenderId(getUserId());
        this.dataList = response.data;
        this.loadingIndicator = false;
    },
    data() {
        return {
            display: false,
            visibleLeft: false,
            dataList: [],
            loadingIndicator:false,
            filters: null,
        };
    },

    methods: {
        edit(item) {
            this.$router.push({ name: 'readMessage', params: { senderId: item.senderId, receiverId: item.receiverId, messageId: item.id } });
        },
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'receiver.fullName': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isRead': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },

    },

};

</script>


<style scoped>

</style>
