<template>
    <div class='p-fluid p-0 flex flex-column'>
        <div class='grid'>

   

            <div class='col-12'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <div class='header-left-section flex align-items-center justify-content-start mb-3 md:mb-0'>
                        <div class='align-items-center justify-content-start mb-0 md:mb-0'>
                            <h3 class='mb-0'>Mesajlarım</h3>
                        </div>
                    </div>
                </div>

                <span>
					<hr class='mb-4'>
				</span>
                
                <div class='field grid'>
                    <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                    <div class='col-12 md:col-12'>
                        <Button label='Gelen Talepler' icon='pi pi-inbox' :badge="receivedCount" style="font-size: 1.3rem" @click='gotoIncomingList'></Button>
                    </div>
                </div>

                <div class='field grid'>
                    <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                    <div class='col-12 md:col-12'>
                        <Button label='Giden Talepler' icon='pi pi-send' :badge="sentCount" style="font-size: 1.3rem" @click='gotoOutgoingList'></Button>
                    </div>
                </div>

                <div class='field grid'>
                    <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                    <div class='col-12 md:col-12'>
                        <Button label='Yeni Mesaj' class="p-button-danger" icon='pi pi-pencil' style="font-size: 1.3rem" @click='addNewMessage'></Button>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
<script>

import MessageService from '@/services/messageService';
import { getUserId } from '@/pages/common/commonConstantFunctions';

export default {
    _messageService: null,
    data() {
        return {
            receivedCount:"0",
            sentCount:"0"
        }
        },
    created() {
        this._messageService = new MessageService();
    },
    async mounted() {
        let userId = getUserId();
        let messageStatusCount = await this._messageService.getMessageCounts(userId);
        if (messageStatusCount.isSuccess) {
            this.receivedCount = messageStatusCount.data.receivedCount.toString();
            this.sentCount = messageStatusCount.data.sentCount.toString();
        }
    },
    methods:{
        async gotoIncomingList() {
            await this.$router.push({ name: 'incomingMessages' });
        },
        async gotoOutgoingList() {
            await this.$router.push({ name: 'outgoingMessages' });
        },
        async addNewMessage() {
            await this.$router.push({ name: 'addMessage'});
        },
    }
}

</script>