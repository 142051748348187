import { deleteDataWithUrl, getData, getPaginatedData, insertData, patchData } from './commonService';

export default class MessageService {

    #defaultUrl = '/api/Message';
    #defaultResourceName = 'Mesaj';

    async getMessageById(senderId, receiverId, messageId)
    {
        return await getData(this.#defaultUrl + '/query?receiverId=' + receiverId + '&senderId=' + senderId + '&messageId='+ messageId +'&isActive=true');
    }
    async getMessagesBySenderId(senderId) {
        return await getPaginatedData(this.#defaultUrl + '/query?senderId=' + senderId + '&isActive=true&parentMessageId=0', this.#defaultResourceName);
    }
    async getMessagesByReceiverId(receiverId) {
        return await getPaginatedData(this.#defaultUrl + '/query?receiverId=' + receiverId + '&isActive=true&parentMessageId=0', this.#defaultResourceName);
    }

    async getMessageCounts(userId) {
        return await getData(this.#defaultUrl + "/read-status?userId=" + userId)
    }

    async insertMessage(dataItem) {
        const createData = {
            "receiverId": dataItem.receiverId,
            "senderId": dataItem.senderId,
            "parentMessageId": dataItem.parentMessageId,
            "text": dataItem.text,
            "type": dataItem.type,
            "status": "Yeni",
            "subject": dataItem.subject
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async insertMessageAsDraft(dataItem) {
        const createData = {
            "receiverId": dataItem.receiverId,
            "senderId": dataItem.senderId,
            "parentMessageId": dataItem.parentMessageId,
            "text": dataItem.text,
            "type": dataItem.type,
            "status": "Taslak",
            "subject": dataItem.subject,
            "isActive": dataItem.isActive
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async deleteMessagePicture(fileType, resourceId, resourceType)
    {
        return await deleteDataWithUrl("/api/Upload/file?fileType=" + fileType + "&resourceId=" + resourceId + "&resourceType=" + resourceType);
    }

    async updateMessage(messageId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "receiverId", "value": dataItem.receiverId},
            {"op": "replace", "path": "text", "value": dataItem.text},
            {"op": "replace", "path": "type", "value": dataItem.type},
            {"op": "replace", "path": "status", "value": dataItem.status},
            {"op": "replace", "path": "subject", "value": dataItem.subject},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
        ];

        return await this.patchMessage(messageId, patchDocument);
    }

    async updateMessageReceiverReadStatus(messageId, readStatus) {
        const patchDocument = [
            {"op": "replace", "path": "isReceiverRead", "value": readStatus}
        ];

        return await this.patchMessage(messageId, patchDocument);
    }

    async updateMessageAnswer(messageId, answerText) {
        const patchDocument = [
            {"op": "replace", "path": "text", "value": answerText},
            {"op": "replace", "path": "isActive", "value": true},
            {"op": "replace", "path": "status", "value": "Açık"},
            {"op": "replace", "path": "isReceiverRead", "value": false},
            {"op": "replace", "path": "isSenderRead", "value": false},
        ];

        return await this.patchMessage(messageId, patchDocument);
    }

    async updateMessageSenderReadStatus(messageId, readStatus) {
        const patchDocument = [
            {"op": "replace", "path": "isSenderRead", "value": readStatus}
        ];

        return await this.patchMessage(messageId, patchDocument);
    }

    async updateMessageStatus(messageId, status, closeDate) {
        const patchDocument = [
            {"op": "replace", "path": "status", "value": status},
            {"op": "replace", "path": "closeDate", "value": closeDate}
        ];

        return await this.patchMessage(messageId, patchDocument);
    }

    async patchMessage(id, patchDocument) {
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }

}